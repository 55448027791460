import React, { forwardRef, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Loading from '../../../../Common/Loading';
import { Margin } from '../../../../Common/styled/Margins';
import { Spacer } from '../../../../Common/styled/Spacer';
import { Row, Column } from '../../../../Common/styled/Groups';
import { Form } from '../../../../Common/styled/Form';
import { TextFieldSelectUI } from '../../../../../componentsUI/TextFieldSelect';
import { GET_HOSPITAL_TEAM } from '../../../../../graphql/queries';
import { ADD_HOSPITAL_TEAM_MEMBER } from '../../../../../graphql/mutations';
import { parseGraphqlErrors } from '../../../../../utils/FormikUtils';
import { roleOptions } from '../TeamUtils';
import { TextFieldUI } from '../../../../../componentsUI/TextField';
import { AlertUI } from '../../../../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
      width: '100%',
      maxWidth: 800,
    },
    '& .MuiTypography-h6': {
      fontWeight: 600,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const NewTeamMemberDialog = ({ open, onClose }) => {
  const { t } = useTranslation();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);

  const [email, setEmail] = useState('');
  const [updating, setUpdating] = useState(false);
  const [serverError, setServerError] = useState(false);
  const classes = useStyles();
  const language = i18n && i18n.language;

  // Maintaining mounted state is important as we don't want to update state
  // in the apollo client callback if the component is unmounted
  let isMounted = false;

  useEffect(() => {
    isMounted = true;

    return () => {
      isMounted = false;
    };
  });

  const [addTeamMember, { loading }] = useMutation(ADD_HOSPITAL_TEAM_MEMBER, {
    onCompleted: ({ addTeamMemberToHospital: result }) => {
      if (result === null) {
        toast('The doctor has received an invitation.', { className: 'toast-success' });
      } else {
        toast(`${result.user.name} ${result.user.surname} has been added to the team.`, { className: 'toast-success' });
      }
      setUpdating(false);
      onClose();
    },
    onError: (addTeamMemberError) => {
      if (isMounted && addTeamMemberError.graphQLErrors && addTeamMemberError.graphQLErrors.length) {
        setServerError(addTeamMemberError.graphQLErrors[0].message);
      }
      toast(`There was an error adding ${email} to the team. Please try again.`, { className: 'toast-error' });
      setUpdating(false);
    },
    refetchQueries: [{ query: GET_HOSPITAL_TEAM, variables: { uuid: hospitalUuid } }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async (form, { setErrors, resetForm }) => {
    setServerError(false);
    setUpdating(true);
    setEmail(form.email);
    try {
      await addTeamMember({
        variables: {
          input: {
            hospitalUuid,
            language,
            userEmail: form.email.toLowerCase(),
            role: form.role,
          },
        },
      }).then((res) => {
        if (res && res.data) resetForm({});
      });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  const closeModal = () => {
    setServerError(false);
    onClose();
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t('required.field')).email(t('invalid.email')),
    role: Yup.string().required().oneOf(['STANDARD', 'MANAGER']),
  });

  const initialValues = {
    email: '',
    role: 'STANDARD',
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeModal}
    >
      <DialogTitle>{t('team.add.member')}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form autoComplete="off" id="addTeamMember">
              <Margin mx-0>
                <Row>
                  <Column>
                    <TextFieldUI
                      name="email"
                      label={t('email')}
                      props={props}
                    />
                  </Column>
                  <Spacer />
                  <Column>
                    <TextFieldSelectUI
                      name="role"
                      label={t('role')}
                      props={props}
                      options={roleOptions}
                    />
                  </Column>
                </Row>
              </Margin>

              <Margin mx-0 mt-5 mb-4>
                <Row>
                  {(updating || loading) && <Loading />}
                  {serverError ? (
                    <AlertUI title="Error" severity="error">
                      {t('server.error')}
                    </AlertUI>
                  ) : null}
                </Row>
              </Margin>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          form="addTeamMember"
          type="reset"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          form="addTeamMember"
          type="submit"
        >
          {t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
