import React from 'react';

import Layout from '../../../components/MenuLayout';
import { Team } from '../../../components/Hospital/Management/Team/Team';

export default () => (
  <Layout type="settings">
    <Team />
  </Layout>
);
